body {
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  display: flex;
  flex-flow: column;
  background-color: #fefefe;
  padding: 0;
  margin: 0;
  min-height: 100vh;
  font-family: var(--font-roboto), Helvetica, Arial, sans-serif;
}

#__next {
  display: flex;
  flex-flow: column;
  flex: 1 1;
  height: 100%;
  width: calc(100vw);
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media print {
  @page {
    size: letter;
    margin: 0.1in;
    margin-top: 0.3in;
  }
}
